import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import { ClientPermissions, RedeemCodeFn, TokenContext, TokenContextData } from './context/TokenContext';
import { claimToken } from './helpers/claimHelper';
import { checkTokenScopes, decode, isSAT, isSIRT, isTokenValid, matchingPetParents } from './helpers/tokenHelper';

const selectToken = ({
  currentToken,
  incomingToken,
  backup,
}: {
  currentToken?: string;
  incomingToken?: string;
  backup?: string;
}): string => {
  // Case #1 - We just have a backup
  if (!currentToken && !incomingToken) {
    if (backup) {
      return backup;
    } else {
      return '';
    }
  }
  // Case # 2 - We have an incoming token and no current token
  else if (!currentToken && incomingToken) {
    // If this new token is a SAT token, replace the backup
    if (isTokenValid(incomingToken)) {
      return incomingToken;
    } else if (backup) {
      if (matchingPetParents(incomingToken, backup)) {
        return backup;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  // Case #3 - We have a current token and no incoming token
  else if (currentToken && !incomingToken) {
    if (isTokenValid(currentToken)) {
      return currentToken;
    } else if (backup) {
      if (matchingPetParents(currentToken, backup)) {
        return backup;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  // Case #4 we have a current token AND an incoming token
  else if (currentToken && incomingToken) {
    const currentValid = isTokenValid(currentToken);
    const incomingValid = isTokenValid(incomingToken);
    if (!currentValid && !incomingValid) {
      if (backup && matchingPetParents(currentToken, incomingToken) && matchingPetParents(incomingToken, backup)) {
        return backup;
      } else {
        return '';
      }
    } else if (currentValid && !incomingValid) {
      if (matchingPetParents(currentToken, incomingToken)) {
        return currentToken;
      } else {
        return '';
      }
    } else if (!currentValid && incomingValid) {
      return incomingToken;
    } else if (currentValid && incomingValid) {
      // If the new token is for a new pet parent, use that
      if (!matchingPetParents(currentToken, incomingToken)) {
        return incomingToken;
      } else {
        // Assuming they have the same pet parent
        if (isSIRT(currentToken) && isSIRT(incomingToken)) {
          // Choose the one with the latest expiration date
          const currentExp = +decode(currentToken)?.exp;
          const incomingExp = +decode(incomingToken)?.exp;
          if (currentExp > incomingExp) {
            return currentToken;
          } else {
            return incomingToken;
          }
        } else if (isSIRT(currentToken) && !isSIRT(incomingToken)) {
          return currentToken;
        } else {
          return incomingToken;
        }
      }
    }
  }
  return '';
};
const getBackupToken = (): string | undefined => {
  return localStorage.getItem(PersistedStateKeys.CachedSimpleAccessToken) || undefined;
};
const setBackupToken = (token: string): void => {
  return localStorage.setItem(PersistedStateKeys.CachedSimpleAccessToken, token);
};
const initialToken = (): string => {
  try {
    const localToken = localStorage.getItem(PersistedStateKeys.CachedToken) || '';
    const urlToken = new URLSearchParams(window.location.search).get('token') || '';
    const backupToken = getBackupToken();
    const selected = selectToken({
      currentToken: localToken,
      incomingToken: urlToken,
      backup: backupToken,
    });
    if (isSAT(selected)) {
      setBackupToken(selected);
    } else if (isSAT(urlToken) && matchingPetParents(selected, urlToken)) {
      setBackupToken(urlToken);
    } else if (isSAT(localToken) && matchingPetParents(selected, localToken)) {
      setBackupToken(localToken);
    } else if (backupToken && !matchingPetParents(backupToken, selected)) {
      setBackupToken('');
    }
    return selected;
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const useTokenContext = (): TokenContextData => useContext(TokenContext);

export const TokenProvider = (props: { children: ReactNode }): JSX.Element => {
  const [token, setToken] = useState(initialToken());
  // Make sure we persist our token
  useEffect(() => {
    localStorage.setItem(PersistedStateKeys.CachedToken, token);
  }, [token]);

  const hasScopes = (checkScopes: ClientPermissions | ClientPermissions[]): boolean => {
    return checkTokenScopes(token, checkScopes);
  };

  // This is to get around the fact that we might need the initial token for apollo to get this token
  const redeemCode: RedeemCodeFn = async (code, client) => {
    // We have to do this here because the client itself depends on our old token
    const newToken = await claimToken(code, client);
    if (newToken) {
      const oldToken = token;
      setToken(newToken);
      if (isSAT(oldToken)) {
        setBackupToken(oldToken);
      }
      return true;
    } else {
      return false;
    }
  };

  const expiresAt = useMemo(() => {
    const exp = decode(token)?.exp;
    if (exp) return +exp * 1000;
    else return null;
  }, [token]);

  const getLiveToken = (): string => {
    const now = Date.now();
    if (expiresAt && now >= expiresAt) {
      const backup = getBackupToken();
      if (isTokenValid(backup)) {
        setToken(backup);
        return backup;
      } else {
        return '';
      }
    }
    return token;
  };

  const logout = (clinicId: string, deactivated?: boolean): void => {
    setToken('');
    setBackupToken('');
    localStorage.setItem(PersistedStateKeys.CachedLastUsedCode, '');
    localStorage.setItem(PersistedStateKeys.CachedToken, '');
    localStorage.setItem(PersistedStateKeys.ClinicAuthArray, '');
    localStorage.setItem(PersistedStateKeys.ClinicAuthIdentity, '');
    localStorage.setItem(PersistedStateKeys.LoginType, '');

    window.location.href = deactivated ? '/inactive' : `/login?clinicId=${clinicId}`;
  };
  return (
    <TokenContext.Provider value={{ token, getLiveToken, redeemCode, hasScopes, logout, setToken }}>
      {props.children}
    </TokenContext.Provider>
  );
};
